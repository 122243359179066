<template>
  <div class="tabbar df aic jcsb bsbb">
    <van-tabbar route v-model="active" :border="false" fixed :safe-area-inset-bottom="true" active-color="#1a90c0">
      <van-tabbar-item to="/home">
        <span class="spansize">首頁</span>
        <template #icon="props">
          <img :src="props.active
              ? require('../../assets/images/xg/home.png')
              : require('../../assets/images/xg/home1.png')
            " />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/c2c">
        <span class="spansize">兑换</span>
        <template #icon="props">
          <img :src="props.active
              ? require('../../assets/images/xg/SWAP1.png')
              : require('../../assets/images/xg/SWAP2.png')
            " />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/dao">
        <span class="spansize">DAO</span>
        <template #icon="props">
          <img :src="props.active
            ? require('../../assets/images/xg/DAO1.png')
            : require('../../assets/images/xg/DAO2.png')
            " />
        </template>
      </van-tabbar-item>
      <!-- 购物车 -->
      <!-- <van-tabbar-item to="/shoppingCart" badge="5">
        <span>购物车</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/images/xg/tab2_1.png')
                : require('../../assets/images/xg/tab2_2.png')
            "
          />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item to="/wallet">
        <span class="spansize">資產</span>
        <template #icon="props">
          <img :src="props.active
              ? require('../../assets/images/xg/wallet.png')
              : require('../../assets/images/xg/wallet1.png')
            " />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user1">
        <span class="spansize">我的</span>
        <template #icon="props">
          <img :src="props.active
              ? require('../../assets/images/xg/my.png')
              : require('../../assets/images/xg/my1.png')
            " />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "FooterTabbar",
  data() {
    return {
      active: 0,
      icon: {
        active: "../../assets/images/xg/tab1_1.png",
        inactive: "../../assets/images/xg/tab1_2.png",
      },
    };
  },
  methods: {
    shopping() {
      this.$router.push("/fund");
    },
    showToast() {
      Toast({
        message: '暂未开放',
        icon: 'success',
        className: 'noticeWidth'
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabbar {

  .spansize {
    font-size: 32px !important;
    color: #fff;
  }

  .van-tabbar  {
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 2rem;
      background-color: #181a20;
      .van-tabbar-item--active {
        color: #1e2329;
      }
  }

  a {
    color: #fff;

    &.active {
      color: red;

    }
  }
}
.van-tabbar-item--active{
  background-color: #1e2329;
  height: 2rem;
}
::v-deep .van-tabbar-item__icon .van-icon {
  font-size: 45px !important;
}

::v-deep .van-tabbar-item__icon img {
  height: 55px;
  margin-bottom: 5px;
}
</style>
