<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    let d = window.localStorage.getItem("pageTypehbui");
    if (d && d === "app") {
      let dom = document.getElementById("app");
      dom.className = "bar-height";
    }
  },
};
</script>

<style lang="less">
@import './assets/css/font-face.css';
#app {
  // font-family: pingfangs;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505050;
}
body,
html {
  background-color: #f8f8f8;
  padding: 0;
  margin: 0;
  height: 100%;
}
.bar-height .van-nav-bar {
  padding-top: 24px;
}
input::-webkit-input-placeholder {
    /* placeholder颜色 */
    /* placeholder字体大小 */
    font-size: 28px;
}
input[type="text"]{
  font-size: 40px !important;
}
input[type="tel"]{
  font-size: 40px !important;
}
input[type="search"]{
  font-size: 28px !important;
}
input[type="password"]{
  font-size: 40px !important;
}
</style>
