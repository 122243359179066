import request from './request'
import ajax from './ajax'
import ajaxc from './ajaxc'
import ajaxCopy from './ajaxCopy'
// -------公共-------
// 登录
export const login = (params) => ajax.post('/login', params)
// 注册
export const register = (params) => request.post('/register', params)
//忘记密码
export const forget = (params) => ajax.post('/Login/forgetPassword', params)
// 获取图形验证码
export const getCaptcha = () => request.get('/getCaptcha')
// 获取短信验证码
export const smsCode = (params) => request.post('/smsCode?mobile=' + params)
// 领取质押收益
export const gainDayReward = (params) => request.post('/gainDayReward' , params)
// 赎回质押
export const backPledge = (params) => request.post('/backPledge' , params)
// 獲取ROC價格
export const getRocPrice = (params) => request.post('/getRocPrice' , params)
// 兌換頁面獲取用戶信息
export const getTradeOtherInfo = (params) => request.post('/getTradeOtherInfo' , params)
// 获取参与质押
export const setPledge = (params) => request.post('/setPledge' , params)
// 绑定推荐人
export const bindInviteCode = (params) => request.post('/bindInviteCode' , params)
// 获取质押列表
export const getPledgList = () => request.get('/getPledgList')
// 获取系统配置
export const getWebConfig = () => request.get('/home/getWebConfig')
// 获取数据云信息
export const getTgData = () => request.get('/getTgData')
// 获取買賣單列表
export const getTradeOrder = (params) => request.post('/getTradeOrder', params)
// 发起订单
export const setTradeOrder = (params) => request.post('/setTradeOrder', params)
// 买入
export const toBuy = (params) => request.post('/toBuy', params)
// 卖出
export const toSell = (params) => request.post('/toSell', params)
// 上传图片
export const upload = (params) => request.post('/common/upload', params)
// 看视频赠送积分
export const videoSendScore = (params) => request.post('/user/videoSendScore', params)
// -------首页-------
// 轮播图
export const getBanner = () => request.get('/home/getBanner')
// 魔盒列表
export const getMhList = () => request.get('/user/getMhList')
// 魔盒奖品列表
export const getMhPrizeList = (id) => request.get('/user/getMhPrizeList/' + id)
// 用户上传的数藏
export const getshucang = () => request.get('/user/getshucang')
// 获取积分列表
export const getScoreType = () => request.get('/user/getScoreType')
// 获取交易类型
export const getexchangeType = () => request.get('/user/getexchangeType')
// 获取交易类型
export const exchangeRate = () => request.get('/user/exchangeRate')

// 产品列表
export const getProductList = (type) =>
  request.get('/product/getProductList/' + type)
// 产品详情
export const getProductDetail = (id) => request.get('/product/detail/' + id)
// 购买
export const submitOrder = (params) => request.post('/product/scanPay', params)
// 线上购买
export const submitOrder1 = (params) => request.post('/product/onlinePay', params)
// 充值
export const recharge1 = (params) => request.post('/recharge', params)

// 充值--百惠
export const recharge2 = (params) => request.post('/user/pay', params)
// 开启魔盒
export const openMangHe = (params) => request.post('/user/openMangHe', params)
// 开启幸运魔盒
export const openLuckMh = () => request.get('/user/openLuckMh')
// request.post('/product/submitOrder', params)

// -------个人中心-------
// 个人信息
export const getUserInfo = () => request.get('/user/getUserInfo')
// 用户拥有的产品
export const getUserProductList = (type) =>
  request.get('/product/getUserProductList/' + type)
// 充值配置
export const getPayConfig = () => request.get('/home/getPayConfig')
// 充值
export const recharge = (params) => request.post('/user/recharge', params)
// 转账汇款
export const recharge12 = (params) => request.post('/user/addPayment', params)
// 上传藏品
export const addusershucang = (params) => request.post('/user/addusershucang', params)
// 购买记录
export const getPaymentLog = () => request.get('/user/paymentLog')
// 视频列表
export const getVideoList = () => request.get('/user/videoList')
// 收支明细
export const getIncome = (params) => request.get('/user/getIncome', { params })

export const getGuQuan = (params) => request.get('/user/getGuQuan', { params })
// 提现
export const withdraw = (params) => request.post('/user/withdraw', params)
// 我的团队
export const getMyTeam = () => request.get('/user/team')
// 我的直推
export const getZhitui = () => request.get('/user/zhitui')
// 邀请好友
export const getInvite = () => request.get('/user/getInvite')
// 签到
export const userClock = (params) => request.post('/user/userClock?days=' + params.days, params)
// 提现记录
export const getWithdrawList = () => request.get('/user/withdrawLog')
// 更新用户信息
export const updateUser = (params) => request.post('/user/updateUser', params)
// 设置密码
export const updatePassword = (params) =>
  request.post('/user/updatePassword', params)
// 设置支付密码
export const setPayPwd = (params) => request.post('/user/setPayPwd', params)
// 检测支付密码是否正确
export const checkPayPwdIsCorrect = (params) =>
  request.post('/user/checkPayPwdIsCorrect', params)
// 消息中心
export const getMessageList = (params) => request.post('/home/getMessageList',params)
// 参数获取
export const getDataByKey = (key) => request.get('/home/getByParamKey/' + key)

export const getHuobiList = () => request.get('/huobi/getHuobiList')

export const buyHuobi = (params) => request.post('/huobi/buyHuobi', params)

export const getActivationLog = () => request.get('/user/activationLog')

// 绑定银行卡
// export const bindBankInfor = (params) =>
//   request.post('/user/bindBankInfor', params)

// 实名认证
export const certification = (params) =>
  request.post('/user/certification', params)

// 币种变现
export const realization = (params) =>
  request.post('/product/realization', params)

// 币种互换
export const accountTransfer1 = (params) =>
  request.post('/product/accountTransfer', params)

  // 币种互换
  export const accountTransfer = (params) =>
    request.post('/user/accountTransfer', params)
// 积分兑换仓单
export const exchange = (params) =>
  request.post('/user/exchange', params)


// 余额互换
export const balanceTransfer = (params) =>
  request.post('/product/balanceTransfer', params)

// 获取邀请奖励比例
export const getCommission = (params) =>
  request.get('/home/getCommission', params)

export const getUserInfoSign = () => request.get('/user/info')

// export const postDoSign = () => request.get('/user/info')

// 添加店铺 √
export const getShopMsg = (params) => request.post("/open_shop/add", params)

// 店铺管理
export const shopManage = (params) => request.post("/store_management/add", params)

// 店铺管理-获取数据 √
export const getShopListMsgReq = (params) => ajax.post("/store_management/getSmListC", params)

// 商家入驻 √
export const getShopList = (params) => ajax.post('/rest/merchant_settlement/getList', params)

// 商品管理-编辑
export const goodsManage = (params) => ajax.post("/api/commodity_management/up", params)

// 商品管理-删除
export const deleteGood = (params) => ajax.post("/api/commodity_management/del", params)

// 商品管理-获取数据列表
export const getGoodsList = (params) => ajax.post("/api/commodity_management/getList", params)

// 商品管理新增数据 √
export const addGoodsMsg = (params) => ajax.post("/api/commodity_management/add", params)

// 商品分类-新增数据 √
export const addCategory = (params) => ajax.post("/api/commodity_management_class/add", params)

// 商品分类-获取数据
export const goodsCategory = (params) => ajax.post("/api/commodity_management_class/getList", params)

// 商品分类-编辑
export const goodsCateEdit = (params) => ajax.post("/api/commodity_management/up", params)

// 商品分类-删除
export const goodsCateDelete = (params) => ajax.post("/api/commodity_management/del", params)

// 购买商品-创建订单 √
export const createOrderReq = (params) => ajax.post("/Order/buy_1", params)

// 购买商品-确认支付 √
export const orderPay = (params) => ajaxCopy.post("/order/buy_2", params)

// 订单管理-获取我的订单
export const getMyOrderReq = (params) => ajax.post("/order/getList", params)

// 订单管理-修改订单
export const amendOrderReq = (params) => ajax.post('/api/order/up', params)

// 我的-绑定银行卡
export const bindBankCard = (params) => ajax.post("/user/bindBankInfor", params)

// 商家-商品评价新增数据
export const getNewEvaluateData = (params) => ajax.post('/pingjia/InPingjiaAdd', params)

// 商家-展示所有评价 √
export const getAllEvaluate = (params) => ajax.post("pingjia/getPingjiaList", params)

//我的-展示所有评价
export const getAllMyEvaluate = (params) => ajax.post('/pingjia/getPingjiaList', params)

//我的-编辑评价
export const editMyComment = (params) => ajax.post('/api/evaluate/up', params)

// 删除评价
export const deleteComment = (params) => ajax.post('/api/pingjia/del', params)

// 获取banner √
export const getHomeBanner = () => ajax.post('/home/getBanner')

// 获取导航分类
export const getBannerClassReq = (params) => ajax.post('/api/home/getList_page_navigation', params)

//购物车列表
export const shoppingCartList = (params) => ajax.post('/shoppingCart/shoppingCartListC', params)

//商品详情
export const commodityDetail = (params) => ajax.post('commodity_management/commodityDetail', params)

//商品支付
export const shopbuy = (params) => ajax.post('/OrderB/buy1', params)
//会员开通
export const rechargePartner = (params) => ajaxCopy.post('MerchantSettlementC/becomeMember', params)

//商品分类
export const CommodityManagementClass = (params) => ajax.post('CommodityManagementClass/getList', params)

//根据类别查询商品
export const commodityManagement = (params) => ajax.post('commodity_management/getList', params)


//修改密码验证码
export const getCode = (params) => ajax.post('/user/getCode', params)


//流水明细
export const detailed = (params) => ajax.post('/user/getlist', params)

//地址添加
export const ShipAddress = (params) => ajax.post('/ShipAddress/add', params)
//地址列表
export const listShipAddress = (params) => ajax.post('/ShipAddress/getList', params)
//修改地址
export const upShipAddress = (params) => ajax.post('/ShipAddress/up', params)
//删除地址
export const delShipAddress = (params) => ajax.post('/ShipAddress/del', params)
//验证码
// export const bindVerify = (params) => ajaxc.post('/login/bind_verify', params)

//验证码
export const bindVerify = (params) => ajaxc.post('/sendCode', params)

//首页商品分类商品
export const CommodityManagementCClass = (params) => ajax.post('/CommodityManagementClass/getCCList', params)

//资产提现
export const AssetWithdrawal = (params) => request.post('/user/withdraw', params)
//行情数据
export const getGate = () => request.get('/getGate')
